export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Work',
    icon: 'BriefcaseIcon',
    auth: ['admin', 'manager'],
    children: [
      {
        title: 'All list',
        route: { name: 'work.list' },
        icon: 'ListIcon',
      },
    ],
  },
  {
    title: 'User',
    icon: 'UserIcon',
    auth: ['admin', 'manager'],
    children: [
      {
        title: 'All list',
        route: { name: 'user.list' },
        icon: 'UsersIcon',
      },
    ],
  },
  {
    title: 'Company/Client',
    icon: 'ArchiveIcon',
    auth: ['admin', 'manager'],
    children: [
      {
        title: 'All list',
        route: { name: 'company.list' },
        icon: 'ListIcon',
      },
    ],
  },
  {
    title: 'Task',
    icon: 'CheckSquareIcon',
    auth: ['executive', 'accounts'],
    children: [
      {
        title: 'Task List',
        route: { name: 'tasks' },
        icon: 'ListIcon',
      },
    ],
  },
]
