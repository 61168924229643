<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="http://bitspiders.com/"
        target="_blank"
      ><b-img
        src="https://bitspiders.com/bitspiders-logo.png"
        fluid
        width="100"
        alt="Fluid image"
      /></b-link>
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>
  </p>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'

export default {
  components: {
    BLink, BImg,
  },
}
</script>
